<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<route>
{
  "redirect": {"name": "App-Companies"}
}
</route>

<script>
import store from '@/store'
import { Company } from '@/store/models'

export default {
  name: 'App',
  async beforeRouteEnter (to, from, next) {
    const response = await store.dispatch('app/getMe')
    if (response?.status === 200) {
      await Company.api().get()
      next()
    } else {
      await store.dispatch('auth/logout')
    }
  }
}
</script>
